<template>
  <div class="inside-page-content">
    <div class="paper">
      <div class="top-search">
        <top-filter :typeList="typeList" @handleSearchParams="handleSearch"></top-filter>
      </div>
      <div class="pro-list">
        <div class="list" v-for="i in Math.ceil(dataList.length/4)" :key="'row'+i">
          <div class="item" v-for="(item) in dataList.slice((i-1)*4,i*4)" :key="item.id">
            <img class="itemImg" :src="item.pictureUrl" />
            <div class="des">
              <div class="item-title h2">{{item.propertyName}}</div>
              <div class="num h2" @click="handleDetailClick(item.id)">查看详情</div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="left">共 {{total}} 条</div>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :page-size="8"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopFilter from './components/topFilter.vue'
export default {
  name: 'knowledgeList',
  components: {
    TopFilter
  },
  data() {
    return {
      dataList: [],
      total: null,
      page: {
        pageNum: 1,
        pageSize: 8
      },
      queryInfo: {
        keywords: null,
        type: 1
      },
      typeList: [
        {
          name: '技术水平',
          code: 'technicalLevel',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '国际领先',
              val: 1,
            },
            {
              name: '国际先进',
              val: 2,
            },
            {
              name: '国内领先',
              val: 3,
            },
            {
              name: '国内先进',
              val: 4,
            },
            {
              name: '其他',
              val: 5,
            }
          ]
        },
        {
          name: '合作类型',
          code: 'cooperationType',
          current: null,
          val: [
            {
              name: '全部',
              val: null,
            },
            {
              name: '作价投资',
              val: 1,
            },
            {
              name: '合作',
              val: 2,
            },
            {
              name: '开发',
              val: 3,
            },
            {
              name: '许可',
              val: 4,
            },
            {
              name: '转让',
              val: 5,
            }
          ]
        },

      ]
    }
  },
  computed: {
  },
  mounted() {
    this.handleSearch()
  },
  methods: {
    getList() {
      let params = Object.assign(this.page, this.queryInfo)
      this.$api.intellectualProperty.infoList(params).then((res) => {
        let data = res.data;
        this.dataList = data.rows
        this.total = data.total
      })
    },
    handleSearch(params) {
      // 发起请求
      this.queryInfo = params
      // this.queryInfo.keywords = params.keywords
      this.getList();
    },
    handleDetailClick(id) {
      this.$router.push({ path: "/intellectualProperty/knowledgeDetail", query: { id: id } });
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.top-search {
  background: #fff;
  padding: 0 40px;
}
.pro-list {
  background: #fff;
  margin-top: 20px;
  padding: 30px;
  .list {
    display: flex;
    align-items: flex-start;
    .item {
      border: 1px solid #ececec;
      width: 23%;
      margin: 10px;
      box-sizing: border-box;
      height: 300px;
      background: white;
      text-align: center;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      .itemImg {
        //width: 100%;
        height: 160px;
      }
      .des {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        width: 100%;
        height: 140px;
        align-items: start;
        .item-title {
          width: 100%;
          font-family: "MicrosoftYaHei";
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
          font-size: 14px;
        }
        .item-des {
          text-align: left;
          font-size: 14px;
          font-weight: 200;
          color: grey;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .num {
          color: white;
          background-color: #0031bd;
          width: 100px;
          margin-left: 10px;
        }
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #0046c0;
    }
  }
}
</style>
